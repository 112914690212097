import { useEffect, useState } from "react";
import resourceImg from "../../../../assets/Videoresources.png";
import { toast } from "react-toastify";
import axios from "../../../../api/axios";
import { Link } from "react-router-dom";

const VideoResources = ({ courseId }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState({});
  const userId = sessionStorage.getItem("userID");
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`loadvideos/${courseId}/${userId}`);
        const resp = response.data.data;
        console.log(resp.message);

        if (
          resp.message === "" ||
          resp.message === "No record Found" ||
          resp.message === "No video was Found" ||
          resp.message === "No Course was Found"
        ) {
          setVideos([]);
        } else {
          setVideos(resp.message);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseId]);

  // Function to handle rating changes
  const handleRatingChange = (id, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [id]: rating,
    }));

    // Send rating to endpoint
    sendRatingToEndpoint(id, rating);
  };
  // Function to send rating to an endpoint
  const sendRatingToEndpoint = async (id, rating) => {
    const obj = {
      user_id: userId,
      resource_type: "video",
      resource_id: parseInt(id),
      rating,
    };
    try {
      const response = await axios.post(`reviews/${userId}`, obj);
      const resp = response.data;
      if (resp.message === "Review added successfully") {
        toast.success("Review added", {
          theme: "colored",
          autoClose: "2000",
        });
      } else {
        toast.warning("Error !", {
          theme: "colored",
          autoClose: "2000",
        });
      }
    } catch (error) {
      // Handle error
      console.error("Error sending rating:", error);
    }
  };
  return (
    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4 ">
      {loading ? (
        <div className="text-center">
          <span
            className="spinner-border spinner-border-sm text-blue"
            role="status"
          ></span>
        </div>
      ) : (
        <>
          {videos.length === 0 ? (
            <div className="text-center">
              <p>No resource available</p>
            </div>
          ) : (
            <>
              {videos.map((video) => (
                <div className="col" key={video.id}>
                  <div className="card resource-card border-0">
                    <Link
                      to={`/courses/coursepage/resourcepage/${encodeURIComponent(
                        video.VideoLink
                      )}`}
                      className="text-decoration-none text-dark"
                      onClick={() => {
                        sessionStorage.setItem("activeResourceID", video.id);
                        sessionStorage.setItem("activeResourceType", "video");
                      }}
                    >
                      <img src={resourceImg} className="card-img-top" alt="" />
                    </Link>
                    <div className="card-body">
                      <h5 className="card-title">{video.Course}</h5>
                      <div className="footnote-grey d-flex">
                        <span className="lato-12-400">By: {video.Tutor}</span>
                        <div className="ms-auto ratings">
                          {/* 5-star rating input */}
                          {[1, 2, 3, 4, 5].map((star) => (
                            <span
                              key={star}
                              onClick={() => handleRatingChange(video.id, star)}
                              onMouseEnter={() =>
                                setRatings({ ...ratings, hoverRating: star })
                              }
                              onMouseLeave={() =>
                                setRatings({ ...ratings, hoverRating: null })
                              }
                              style={{
                                cursor: "pointer",
                                color:
                                  star <=
                                  (ratings.hoverRating ||
                                    ratings[video.id] ||
                                    0)
                                    ? "gold"
                                    : "gray",
                                transition: "color 0.2s ease-in-out", // Animation duration
                              }}
                            >
                              ★
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default VideoResources;
